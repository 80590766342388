<template>
  <div>
    <div class="classOrder">
      <!-- 标题 订单信息-->
      <div class="wrapsTit">选择套餐</div>
      <div class="detail">
        <div class="wrapsTits">套餐名称</div>
        <div class="wrapsCon">
          <img class="warp-img" :src="detail.img">
          <div class="content-title">{{ detail.tiitle }}</div>
          <div class="content-price">
            <span class="icon">￥</span>{{
              directionPrice && choose.type == 1 ? directionPrice : detail.price
            }}
          </div>
        </div>
        <el-divider />
        <template v-if="$route.query.type == 'oneToOne'">
          <div class="but">
            <div class="price">
              合计:
              <div class="price-icon">￥</div>
              <div class="price-num">
                {{
                  choose.type == 2 && !directionPrice
                  ? detail.price
                  : directionPrice
                }}
              </div>
            </div>
            <div class="but-item" @click="zhifu">提交订单</div>
          </div>
        </template>
        <template v-else>
          <div class="wrapsTits">
            选择套餐
            <span v-if="domain.isShop == 1" class="tips">（组合套餐可组合选择课程购买）</span>
          </div>
          <div class="type">
            <div class="type-item" :class="choose.type == 1 ? 'active' : ''" @click="choose.type = 1">
              全科套餐
              <img v-if="choose.type == 1" class="type-img" src="@/assets/img/study/choose.png" mode="aspectFit">
            </div>
            <div v-if="domain.isShop == 1" class="type-item" :class="choose.type == 2 ? 'active' : ''"
              @click="choose.type = 2">
              组合套餐
              <img v-if="choose.type == 2" class="type-img" src="@/assets/img/study/choose.png" mode="aspectFit">
            </div>
          </div>
          <el-divider />
          <!-- 组合套餐 -->
          <div v-if="choose.type == 2">
            <el-collapse class="docList">
              <el-collapse-item v-for="(item, index) in kcList" v-show="item.kcLIst.length > 0" :key="index" class="item">
                <template slot="title">
                  <div class="docList-title w-b-100">
                    <div class="ellipsis">
                      {{ item.kcName }}
                    </div>
                    <div v-if="item.choose && item.choose.length > 0" class="chooseNum">
                      {{ item.choose ? item.choose.length : 0 }}
                    </div>
                  </div>
                </template>
                <div v-for="(kcitem, kcindex) in item.kcLIst" :key="kcindex" class="docList-item"
                  :class="choose.kcides.includes(kcitem.id) ? 'active' : ''" @click="chooseClick(kcitem, item)">
                  {{ kcitem.yearName }}【{{ liveEnum[kcitem.type] }}】
                  {{ kcitem.title }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <!-- 选择课程方向 -->
          <div v-if="choose.type == 1 && kcBoxList.length > 0">
            <div class="wrapsTits">选择课程方向</div>
            <div class="course">
              <!-- <div class="course-item" :class="choose.kcBoxId == 0 ? 'active' : ''" @click="choose.kcBoxId = 0">
                默认全科套餐
              </div> -->
              <div v-for="(item, index) in kcBoxList" v-if="item.isShow == 0" :key="index" class="course-item"
                :class="choose.kcBoxId == item.id ? 'active' : ''" @click="chooseKb(item)">
                {{ item.name }}
              </div>
            </div>
          </div>
          <!-- 全科套餐包含课程 -->
          <!--  && choose.kcBoxId == 0 -->
          <div v-if="choose.type == 1 && yearsList.length > 0">
            <el-divider />
            <div class="wrapsTits">全科套餐包含课程</div>
            <div class="class-cour">
              <div v-for="(item, index) in yearsList" :key="index">
                {{ index ? "·" : "" }} {{ item.kcName }}
              </div>
            </div>
          </div>
          <!-- 全科配套服务 -->
          <!-- && choose.kcBoxId == 0  -->
          <div v-if="choose.type == 1 && detail.coufig && detail.coufig.length > 0">
            <el-divider />
            <div class="wrapsTits">全科配套服务</div>
            <div v-if="choose.type == 1 && detail.coufig && detail.coufig.length > 0
              " class="typeList">
              <img class="type-img" src="@/assets/img/study/myCourse.png" mode="aspectFit">
              <div>
                <span v-for="(coufig, coufigindex) in detail.coufig" :key="coufigindex">
                  {{ coufigindex ? "·" : "" }}{{ coufig }}
                </span>
              </div>
            </div>
          </div>
          <div class="but">
            <div v-if="choose.type == 2" class="price">
              合计:
              <div class="price-icon">￥</div>
              <div class="price-num">{{ allPrice }}</div>
            </div>
            <div class="but-item" @click="zhifu">提交订单</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getClass } from '@/api/home'
import wxPayment from './wxPayment.vue'
import { getInfo, getDomain } from '@/api/cookies'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
import { Know } from '@/api/know'
import { liveEnum } from '@/api/emun'
const know = new Know()
export default {
  components: { wxPayment },
  data() {
    return {
      liveEnum: liveEnum,
      classId: null,
      domain: getDomain(),
      detail: {}, // 班型详情
      choose: {
        type: 1,
        kcBoxId: 0,
        kcdetail: [],
        kcides: []
      },
      yearsList: [],
      kcBoxList: [],
      kcList: [],
      allPrice: 0,
      directionPrice: 0,
      directionBox: {},
      loading: null
    }
  },
  beforeDestroy() {
    this.loading.close()
  },

  async created() {
    if (this.$route.query.direction) {
      this.choose.kcBoxId = await this.$route.query.direction
    }
    this.userInfo = await getInfo()
    this.$nextTick(() => {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
    })
    this.classId = this.$route.query.classId
    await this.getDetail()
    await this.getkcBox()
    this.getyears()
  },
  methods: {
    // 课程推荐详情
    async getDetail() {
      await getClass(this.classId).then((res) => {
        if (res.data.BX.curForms) {
          const item = res.data.BX
          res.data.BX.curForms = res.data.BX.curForms.split(',')
          const data = []
          if (item.isData == 0) {
            data.push('专属资料')
          }
          if (item.isQuestion == 0) {
            data.push('专属题库')
          }
          if (item.isExamination == 0) {
            data.push('专属考场')
          }
          /*   if (item.jcServer == 0) {
            data.push("专属教材");
          }
          if (item.jfServer == 0) {
            data.push("专属教服");
          } */
          if (res.data.BX.jcServer) {
            data.push('专属教材')
          }
          if (res.data.BX.jfServer) {
            data.push('专属教服')
          }
          item.coufig = data
        }
        this.detail = res.data.BX
        this.$forceUpdate()
      })
    },
    // 获取知识套餐课程
    getkcBox() {
      know.getCoursePackageList(this.classId, 9999, 1).then((res) => {
        res.rows.forEach((element) => {
          console.log(element, this.$route.query.direction)
          if (element.id == this.$route.query.direction) {
            this.directionPrice = element.price
            this.directionBox = element
          }
        })
        this.kcBoxList = res.rows
      })
    },
    /* 选择课程方向 */
    chooseKb(item) {
      console.log(item)
      this.choose.kcBoxId = item.id
      console.log(this.choose.kcBoxId)
      this.choose.name = item.name
      this.directionPrice = item.entrantsPrice
      this.$forceUpdate()
    },
    /* 组合购买内容 */
    async getkc(kid) {
      let data = []
      await know
        .getClassesCourseBuyList(this.classId, undefined, kid, this.userInfo.id)
        .then((res) => {
          if (res.code == 0) {
            for (const item of res.data.audio) {
              item.type = 3
              data.push(item)
            }
            for (const item of res.data.face) {
              item.type = 5
              data.push(item)
            }
            for (const item of res.data.imageText) {
              item.type = 4
              data.push(item)
            }
            for (const item of res.data.live) {
              item.type = 1
              data.push(item)
            }
            for (const item of res.data.video) {
              item.type = 2
              data.push(item)
            }
          }
          console.log(data, 'data')
          data = data.filter((item) => item.entrantsPass == 1)
          console.log(data, 'data1')
        })
      return data
    },
    /* 获取知识套餐包含课程名 */
    async getyears() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
      await know.classesTable(this.classId, this.userInfo.id).then((res) => {
        if (res.code == 0) {
          this.yearsList = res.data.array
          this.kcList = res.data.array
          for (const item of this.kcList) {
            this.getkc(item.kId).then((res) => {
              item.kcLIst = res
            })
          }
          this.$forceUpdate()
        }
      })
      this.loading.close()
    },
    /* 选中组合购买 */
    chooseClick(kcitem, father) {
      if (kcitem.entrantsPass == 1) {
        if (this.choose.kcides.includes(kcitem.id)) {
          this.choose.kcdetail = this.choose.kcdetail.filter((item, index) => {
            return item.id != kcitem.id
          })
        } else {
          this.choose.kcdetail.push(kcitem)
        }
        this.choose.kcides = this.choose.kcdetail.map((item, index) => {
          return item.id
        })
        const price = this.choose.kcdetail.map((item, index) => {
          return item.entrantsPrice
        })
        var sum = 0
        for (const i of price) {
          sum += i
        }
        this.allPrice = sum
        father.choose = father.kcLIst.filter((item, index) => {
          return this.choose.kcides.includes(item.id)
        })
      } else {
        this.$message.warning('此商品为异常商品，不可购买')
      }

      this.$forceUpdate()
    },
    zhifu() {
      if (this.userInfo) {
        if (this.choose.type == 1) {
          if (this.kcBoxList.length > 0 && !this.choose.kcBoxId) {
            return
          }
          this.$router.push(
            `/orders/classOrder?classId=${this.detail.id}&type=${this.$route.query.type}`
          )
          orderClient
            .addOrders(
              this.choose.kcBoxId ? this.choose.kcBoxId : this.classId,
              localStorage.getItem('userId'),
              this.choose.kcBoxId ? this.choose.name : this.detail.tiitle,
              this.choose.kcBoxId ? 16 : 1,
              this.choose.kcBoxId ? this.choose.name : this.detail.tiitle,
              this.choose.kcBoxId ? this.choose.kind : this.detail.kind
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: '个人中心订单页',
                  params: { orderNumber: res.msg }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
        } else {
          const of = new Date().getTime()
          /*  */
          localStorage.setItem(`saveId${of}`, JSON.stringify(this.kcList))
          this.$router.push(
            `/orders/classSure?ids=${this.choose.kcides}&class=${this.classId}&saveId=${of}` /* { name: "组合购买", params: { ids: this.choose.kcides, class: this.classId } } */
          )
        }
      } else {
        Vue.prototype.goLoginView(true)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.classOrder {
  width: 1220px;
  margin: 40px auto;

  // 标题
  .wrapsTit {
    font-size: 28px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    margin-bottom: 32px;

    .tips {
      color: #999999;
    }
  }

  .detail {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 40px;
  }

  // 小标题
  .wrapsTits {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }

  .wrapsCon {
    width: 100%;
    height: 138px;
    background: #f5f7f9;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-top: 20px;
    padding: 24px;
    display: flex;

    .warp-img {
      width: 160px;
      height: 90px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      flex-shrink: 0;
      margin-right: 16px;
    }

    .content-title {
      width: 100%;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
    }

    .content-price {
      font-size: 24px;
      font-family: Microsoft YaHei-Bold;
      font-weight: bold;
      color: #333333;
      line-height: 90px;

      .icon {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
      }
    }
  }

  .type {
    display: flex;
    margin-top: 20px;

    .type-item {
      width: 140px;
      height: 56px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #dddddd;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 56px;
      text-align: center;
      position: relative;
      cursor: pointer;

      &+.type-item {
        margin-left: 20px;
      }
    }

    .type-img {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 32px;
      height: 32px;
    }
  }
}

/* 选择套餐 */
.typeList {
  margin-top: 20px;
  height: 44px;
  background: linear-gradient(118deg, #ffedc8 0%, #f9d996 100%);
  border-radius: 48px 48px 48px 48px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #5f502e;
  line-height: 44px;
  display: inline-flex;
  align-items: center;
  padding: 0px 16px 0px 13px;

  .type-img {
    width: 42px;
    height: 42px;
  }
}

.docList {
  .docList-title {
    height: 48px;
    background: #f5f7f9;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 48px;
    border-bottom: 1px solid #eee;
    padding: 0px 20px;
    position: relative;
    display: flex;
    align-items: center;

    .chooseNum {
      width: 20px;
      height: 20px;
      background: #ff5e51;
      opacity: 1;
      border-radius: 50%;
      line-height: 20px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin-left: 15px;
      flex-shrink: 0;
    }
  }

  /deep/ .el-collapse-item {
    margin-top: 20px;
  }
}

/deep/ .el-collapse {
  border: none;

  .el-collapse-item__wrap {
    border: none;
  }

  .el-collapse-item__header {
    height: 48px;
    background: #f5f7f9;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 48px;
    padding: 0px 20px;
  }

  .el-collapse-item__content {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    .docList-item {
      height: 36px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #dddddd;
      padding: 0px 16px;
      font-size: 14px;
      font-family: PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
      cursor: pointer;
      margin-left: 12px;
      margin-bottom: 20px;
    }
  }
}

/* 选择课程方向 */
.course {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  .course-item {
    height: 36px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #dddddd;
    padding: 0px 16px;
    font-size: 14px;
    font-family: PingFang SC-Regular;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
    cursor: pointer;
    margin-bottom: 24px;

    &+.course-item {
      margin-left: 12px;
    }
  }
}

/*全科套餐包含课程  */
.class-cour {
  margin-top: 16px;
  font-size: 14px;
  font-family: PingFang SC-Regular;
  font-weight: 400;
  color: #666666;
  line-height: 14px;
  display: flex;
  flex-wrap: wrap;
}

.el-divider--horizontal {
  margin: 40px 0px;
}

/* 选中状态 */
.active {
  color: #ff5e51 !important;
  border: 1px solid #ff5e51 !important;
}

.but {
  height: 128px;
  background: #ffffff;
  width: 100%;
  opacity: 1;
  padding: 40px 29px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .price {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
    margin-right: 40px;
    display: flex;
    align-items: flex-end;

    .price-icon {
      color: #ff5e51;
    }

    .price-num {
      color: #ff5e51;
      font-weight: Bold;
      line-height: 32px;
      font-size: 32px;
    }
  }

  .but-item {
    width: 170px;
    cursor: pointer;
    height: 48px;
    background: #ff5e51;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    float: right;
  }
}
</style>